





















































































































































































































































































































import { Vue, Ref, Component } from "vue-property-decorator";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
@Component({
    components: {
        swiper,
        swiperSlide,
    },
})
export default class product extends Vue {
    @Ref("mySwiper") mySwiper: any;
    public reverse = true;
    public isMoving = false;
    public brandIndex: number = 1;
    public tabIndex: number = 5;
    public swiperOption = {
        // pagination: {
        //     el: '.swiper-pagination',
        // },
        spaceBetween: 60,
        initialSlide: 5,

        speed: 800,
        //滑动之后回调函数
        on: {
            slideChangeTransitionStart: () => {
                this.handelMove(true);
                this.getStep(null);
            },
            slideChangeTransitionEnd: () => {
                this.handelMove(false);
                this.getStep(null);
            },
        },
    };
    public mounted() {
        this.getStep(this.tabIndex);
    }

    public handelMove(boolean: boolean) {
        this.isMoving = boolean;
    }

    //on中写了this.stepIndex无法生效？
    public getStep(index: any) {
        this.swiperOption.initialSlide = this.tabIndex = index || (this.mySwiper.swiper && this.mySwiper.swiper.activeIndex);
    }
    public swiperTo(index: any) {
        this.mySwiper.swiper.slideTo(index);
    }
    public mouseoverBrand(index: any) {
        this.brandIndex = index;
    }

    public metaInfo = {
        title: "简单睡眠怎么样几线品牌-简单睡眠品牌介绍-简单睡眠",
        meta: [
            {
                name: "简单睡眠，简单睡眠家纺，品牌介绍，简单睡眠品牌，国民家纺，国民家纺品牌",
                content: "国民家纺品牌简单睡眠，成立至今，始终秉持“舒适睡眠，就是简单”的品牌主张，坚守“匠心品质、真心服务、舒心生活”的品牌初心，努力实现“让十亿中国人享受舒适睡眠“的品牌愿景。",
            },
        ],
    };
}
